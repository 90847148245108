import { Select, Stack, Text, TextInput } from '@mantine/core';
import type { Libraries } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlacesAutocomplete } from '@/components';
import type { Address } from '@/types';
const libraries: Libraries = ['places'];
import { COUNTRY } from '@/constants';

interface AddressDetails {
  street: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  unit: string;
}

interface Props {
  address: Address;
  carmen: Record<
    string,
    {
      code: string;
      name: string;
    }[]
  >;
  api_key: string;
  errors: Record<string, string>;
  onAddressChange: (updatedAddress: Address) => void;
}

const countryMap: Record<string, string> = {
  Canada: 'CA',
  'United States': 'US',
};

export function ClientAddressAutocomplete({
  address,
  carmen,
  api_key,
  errors,
  onAddressChange,
}: Props) {
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: api_key,
    libraries,
  });

  const [formState, setFormState] = useState(address);

  const update = (field: keyof Address, value: string | number) => {
    const updatedState = {
      ...formState,
      [field]: value,
      address_type: 'shipping',
    };
    setFormState(updatedState);
    onAddressChange(updatedState);
  };

  useEffect(() => {
    setFormState((prevState: Address) => {
      const updatedState = { ...prevState };
      (Object.keys(address) as (keyof Address)[]).forEach(key => {
        if (!prevState[key] && address[key] !== undefined) {
          updatedState[key] = address[key];
        }
      });
      return updatedState;
    });
  }, [address]);

  const handlePlaceChange = (details: AddressDetails) => {
    const updatedState = {
      ...formState,
      address_line_1: details.street,
      address_line_2: details.unit,
      city: details.city,
      postal_code: details.postalCode,
      province_state: details.province,
      country: countryMap[details.country] || details.country,
    };
    setFormState(updatedState);
    onAddressChange(updatedState);
  };

  return (
    <Stack>
      <Text fw={700}>{t('collection_site.column.search_address')}</Text>
      {isLoaded && <PlacesAutocomplete onChange={handlePlaceChange} />}
      <Stack>
        <TextInput
          required
          label={t('collection_site.column.street_address')}
          value={formState.address_line_1 || ''}
          onChange={e => update('address_line_1', e.target.value)}
          error={errors['addresses[0].address_line_1']}
          placeholder="Street and number, P.O. box, c/o."
        />
        <TextInput
          value={formState.address_line_2 || ''}
          onChange={e => update('address_line_2', e.target.value)}
          error={errors.address_line_2}
          placeholder="Apartment, suite, unit, building, floor, etc."
        />
      </Stack>
      <TextInput
        required
        label={t('collection_site.column.city')}
        value={formState.city || ''}
        onChange={e => update('city', e.target.value)}
        error={errors['addresses[0].city']}
      />
      <Select
        required
        label={t('collection_site.column.country')}
        value={formState.country || ''}
        onChange={value => update('country', value as string)}
        data={COUNTRY}
        error={errors['addresses[0].country']}
      />
      <Select
        required
        label={t('collection_site.column.province_state')}
        value={formState.province_state || ''}
        onChange={code => {
          const selectedProvince = carmen[formState.country]?.find(
            item => item.code === code,
          );
          if (selectedProvince) {
            update('province_state', selectedProvince.code);
          }
        }}
        data={
          carmen[formState.country]
            ? carmen[formState.country].map(item => ({
                label: item.name,
                value: item.code,
              }))
            : []
        }
        error={errors['addresses[0].province_state']}
      />
      <TextInput
        required
        label={t('collection_site.column.postal_zip_code')}
        value={formState.postal_code || ''}
        onChange={e => update('postal_code', e.target.value)}
        error={errors['addresses[0].postal_code']}
      />
    </Stack>
  );
}
