import { useForm } from '@inertiajs/react';
import {
  Autocomplete,
  Button,
  Card,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressComponent, Field } from '@/components';
import { SITE_TYPE } from '@/constants';
import type {
  Address,
  Carmen,
  CollectionSiteGroup,
  Company,
  ProductBundle,
  Site,
  SiteProductBundle,
} from '@/types';
import { nestedAttributes } from '@/utils';

import { SiteProductBundleEdit } from './_SiteProductBundleEdit';

interface Props {
  site: Site & {
    site_product_bundles?: SiteProductBundle[];
  };
  collectionSiteGroups: CollectionSiteGroup[];
  carmen: Carmen;
  address: Address[];
  productBundle: ProductBundle[];
  companies?: Company[];
}

export function Form({
  site,
  collectionSiteGroups,
  carmen,
  address,
  productBundle,
  companies,
}: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, errors, transform, setError } = useForm(site);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!data.site_product_bundles || data.site_product_bundles.length === 0) {
      setError('site_product_bundles', t('site.message.select_product_bundle'));
      return;
    }
    submit(
      site.id != null ? 'patch' : 'post',
      `/admin/sites/${site.identifier ?? ''}`,
    );
  };

  transform(nestedAttributes(['addresses', 'site_product_bundles']));

  return (
    <form onSubmit={save}>
      <Stack gap="xl">
        <Card>
          <Card.Section>
            {companies != null && (
              <Field label={t('company.singular')} required>
                <Autocomplete
                  required
                  onOptionSubmit={value => {
                    setData('company_id', Number(value));
                  }}
                  data={companies?.map(company => ({
                    value: company.id.toString(),
                    label: company.name,
                  }))}
                />
              </Field>
            )}
            <Field label={t('site.column.description')} required>
              <TextInput
                required
                value={data.description || ''}
                onChange={e => {
                  setData('description', e.target.value);
                }}
                error={errors.description}
              />
            </Field>
            <Field label={t('site.column.site_type')}>
              <Select
                name="role"
                value={data.site_type || ''}
                onChange={value => {
                  setData('site_type', value as string);
                }}
                data={SITE_TYPE.map(type => ({
                  value: type,
                  label: t(`${type}`),
                }))}
                error={errors.site_type}
              />
            </Field>
            <Field label={t('site.column.booking_site_url')}>
              <TextInput
                value={data.booking_site_url || ''}
                onChange={e => {
                  setData('booking_site_url', e.target.value);
                }}
                error={errors.booking_site_url}
              />
            </Field>
            <Field label={t('site.column.booking_method_description')} required>
              <TextInput
                required
                value={data.public_description || ''}
                onChange={e => {
                  setData('public_description', e.target.value);
                }}
                error={errors.public_description}
              />
            </Field>
            <Field label={t('site.column.phone')}>
              <TextInput
                required
                value={data.phone || ''}
                onChange={e => {
                  setData('phone', e.target.value);
                }}
                error={errors.phone}
              />
            </Field>
            <Field label={t('site.column.collection_site_group')}>
              <Select
                value={data.collection_site_group_id?.toString() || ''}
                onChange={value => {
                  setData({
                    ...data,
                    collection_site_group_id: value ? Number(value) : null,
                    parent_lab_id: null,
                  });
                }}
                data={collectionSiteGroups.map(collectionSiteGroup => ({
                  value: collectionSiteGroup.id.toString(),
                  label: collectionSiteGroup.name,
                }))}
                error={errors.collection_site_group_id}
                placeholder="Select a collection site group"
              />
            </Field>

            {data.collection_site_group_id && (
              <Field label={t('site.column.parent_lab')}>
                <Select
                  key={data.collection_site_group_id}
                  value={data.parent_lab_id?.toString() || ''}
                  onChange={value => {
                    setData({
                      ...data,
                      parent_lab_id: value ? Number(value) : null,
                    });
                  }}
                  data={
                    collectionSiteGroups
                      .find(group => group.id === data.collection_site_group_id)
                      ?.collection_sites.map(site => ({
                        value: site.id.toString(),
                        label: site.name,
                      })) || []
                  }
                  error={errors.parent_lab_id}
                  placeholder="Select a collection site"
                />
              </Field>
            )}
          </Card.Section>
        </Card>
        <AddressComponent
          errors={errors}
          value={address != null ? address : []}
          onChange={value => {
            setData('addresses', value);
          }}
          carmen={carmen}
        />
        <SiteProductBundleEdit
          productBundles={productBundle}
          errors={errors}
          value={data.site_product_bundles ?? []}
          onChange={value => {
            setData('site_product_bundles', value);
            setError('site_product_bundles', '');
          }}
        />
        {errors.site_product_bundles && (
          <Text c="red">{errors.site_product_bundles}</Text>
        )}
        <Group justify="end">
          <Button type="submit">{t('common.action.save')}</Button>
        </Group>
      </Stack>
    </form>
  );
}
