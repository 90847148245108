import { Anchor, Card, Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type {
  Address,
  CollectionSiteGroup,
  Corporate,
  Site,
  SiteProductBundle,
} from '@/types';

import { SiteProductBundleShow } from './_SiteProductBundleShow';

interface Props {
  site: Site & {
    corporate?: Corporate;
    addresses?: Address[];
    site_product_bundles?: SiteProductBundle[];
  };
  collectionSiteGroup: CollectionSiteGroup[];
}

export function Details({ site, collectionSiteGroup }: Props) {
  const { t } = useTranslation();
  return (
    <Stack gap="xl">
      <Card>
        <Card.Section>
          <Field label={t('site.column.description')}>{site.description}</Field>
          <Field label={t('site.column.site_type')}>{site.site_type}</Field>
          {site.site_type === 'Branch' && (
            <Field label={t('site.column.corporate')}>
              {site.corporate?.description}
            </Field>
          )}
          <Field label={t('site.column.booking_site_url')}>
            {site.booking_site_url}
          </Field>
          <Field label={t('site.column.booking_method_description')}>
            {site.public_description}
          </Field>
          <Field label={t('site.column.shipping_address')}>
            {
              site.addresses?.find(
                address => address.address_type === 'shipping',
              )?.full_address
            }
          </Field>
          <Field label={t('site.column.phone')}>{site.phone}</Field>
          <Field label={t('site.column.original_primary_contact_email')}>
            {site.email}
          </Field>
          <Field label={t('site.column.collection_site_group')}>
            <Anchor
              href={`/admin/collection_site_groups/${site.collection_site_group_id}`}
            >
              {collectionSiteGroup.find(
                (group: { id: number }) =>
                  group.id === site.collection_site_group_id,
              )?.name ?? []}
            </Anchor>
          </Field>
          <Field label={t('site.column.parent_lab')}>
            <Anchor href={`/admin/collection_sites/${site.parent_lab_id}`}>
              {
                collectionSiteGroup
                  .flatMap(group => group.collection_sites)
                  .find(
                    collectionSite => collectionSite.id === site.parent_lab_id,
                  )?.name
              }
            </Anchor>
          </Field>
        </Card.Section>
      </Card>
      {site.site_product_bundles != null &&
        site.site_product_bundles.length > 0 && (
          <SiteProductBundleShow
            siteProductBundle={site.site_product_bundles}
          />
        )}
    </Stack>
  );
}
