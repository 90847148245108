import { Card, Group, Select, Text, TextInput } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import { COUNTRY } from '@/constants';
import type { Address, Carmen } from '@/types';

interface Props {
  value: Address[];
  onChange: (value: Address[]) => void;
  errors: Record<string, string>;
  carmen: Carmen;
}

export function AddressComponent({
  value = [],
  onChange,
  errors,
  carmen,
}: Props) {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(value);

  const handleInputChange = (field: string, inputValue: string) => {
    const updatedAddresses = localValue.map(address => ({
      ...address,
      [field]: inputValue,
      address_type: 'shipping',
    }));
    setLocalValue(updatedAddresses);
    onChange(updatedAddresses);
  };

  return (
    <>
      {localValue.map(address => (
        <Card key={address.id}>
          <Card.Section withBorder>
            <Group p="md">
              <Text>{t('address.singular')}</Text>
            </Group>
          </Card.Section>
          <Card.Section>
            <Field label={t('address.column.address_line_1')} required>
              <TextInput
                name={'address_line_1'}
                value={address.address_line_1 || ''}
                onChange={e => {
                  handleInputChange('address_line_1', e.target.value);
                }}
                error={errors['addresses.address_line_1']}
              />
            </Field>

            <Field label={t('address.column.address_line_2')}>
              <TextInput
                name={'address_line_2'}
                value={address.address_line_2 || ''}
                onChange={e => {
                  handleInputChange('address_line_2', e.target.value);
                }}
                error={errors['addresses.address_line_2']}
              />
            </Field>
            <Field label={t('address.column.city')} required>
              <TextInput
                required
                name="city"
                value={address.city || ''}
                onChange={e => {
                  handleInputChange('city', e.target.value);
                }}
                error={errors['addresses.city']}
              />
            </Field>

            <Field label={t('address.column.postal_code')} required>
              <TextInput
                required
                name="postal_code"
                value={address.postal_code || ''}
                onChange={e => {
                  handleInputChange('postal_code', e.target.value);
                }}
                error={errors['addresses.postal_code']}
              />
            </Field>
            <Field label={t('address.column.country')} required>
              <Select
                required
                name="country"
                value={address.country || ''}
                onChange={selectedValue => {
                  handleInputChange('country', selectedValue ?? '');
                }}
                data={COUNTRY}
                error={errors['addresses.country']}
              />
            </Field>

            <Field label={t('address.column.province_state')} required>
              <Select
                required
                name="province_state"
                value={address.province_state || ''}
                onChange={selectedValue => {
                  handleInputChange('province_state', selectedValue ?? '');
                }}
                error={errors['addresses.province_state']}
                data={
                  carmen?.[address.country]?.map(item => ({
                    label: item.name,
                    value: item.code,
                  })) ?? []
                }
              />
            </Field>
          </Card.Section>
        </Card>
      ))}
    </>
  );
}
