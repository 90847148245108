import { Link } from '@inertiajs/react';
import { Button, Card } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaExclamationTriangle } from 'react-icons/fa';

import {
  DataGrid,
  IfHasPrivilege,
  Page,
  Pagination,
  Toolbar,
} from '@/components';
import { useUser } from '@/hooks';
import type { Provider } from '@/types';

import KebabMenu from './_KebabMenu';

interface Props {
  providers: Provider[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = [
    'first_name',
    'last_name',
    'email',
    'provider_sites_site_description',
  ];

  const sortCols = ['first_name', 'last_name', 'email', 'role'];

  const provider = useUser<Provider>();

  return (
    <Page
      title="Staff"
      actions={
        <IfHasPrivilege privilege="change_providers">
          <Button component={Link} href="/provider/providers/new">
            Create Staff
          </Button>
        </IfHasPrivilege>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar sortCols={sortCols} searchCols={searchCols} />
          <DataGrid
            data={props.providers}
            columns={[
              {
                header: t('user.column.first_name'),
                accessorFn: row => row.first_name,
              },
              {
                header: t('user.column.last_name'),
                accessorFn: row => row.last_name,
              },
              {
                header: t('user.column.email'),
                accessorFn: row => row.email,
              },
              {
                header: t('user.column.invitation_accepted'),
                cell: ({ row }) => (
                  <>
                    {row.original.invitation_accepted_at != null ? (
                      <div>
                        <FaCheck style={{ color: 'lightblue' }} size={25} />
                      </div>
                    ) : (
                      <div style={{ color: 'orange' }}>
                        <FaExclamationTriangle style={{ marginRight: '5px' }} />
                        Pending
                      </div>
                    )}
                  </>
                ),
              },
              {
                header: t('user.column.status'),
                accessorFn: row =>
                  `${row.status[0].toUpperCase() + row.status.slice(1)}`,
              },
              {
                header: t('user.column.role'),
                accessorFn: row =>
                  t(`provider.role.${row.role ?? 'none'}.label`),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <IfHasPrivilege privilege="change_providers">
                    {row.original.id !== provider.id && (
                      <KebabMenu provider={row.original} />
                    )}
                  </IfHasPrivilege>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
