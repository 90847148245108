import { useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Group,
  Select,
  Stack,
  Textarea,
  TextInput,
} from '@mantine/core';
import { type SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, RequisitionFormSet } from '@/components';
import { AddressAutocomplete } from '@/components/AddressAutocomplete';
import {
  COLLECTION_SITE_BOOKING_PREFERENCE,
  COLLECTION_SITE_REQUISITION_FORM_SET,
} from '@/constants';
import type { CollectionSite } from '@/types';
import { nestedAttributes } from '@/utils';

import { HoursOfOperation } from './_HoursOfOperation';

interface Item {
  day: string;
  open: string;
  close: string;
}

interface Props {
  collectionSite: CollectionSite;
  carmen: Record<
    string,
    {
      code: string;
      name: string;
    }[]
  >;
  api_key: string;
}

export function Form({ collectionSite, carmen, api_key }: Props) {
  const { t } = useTranslation();
  const { submit, data, setData, errors, transform } = useForm(collectionSite);

  const addressDetails = {
    address_line_1: data.address_line_1,
    address_line_2: data.address_line_2,
    city: data.city,
    province_state: data.province_state,
    postal_code: data.postal_code,
    country: data.country,
  };

  const handleAddressChange = (updatedAddress: Partial<CollectionSite>) => {
    setData(prevData => ({
      ...prevData,
      ...updatedAddress,
    }));
  };

  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const parse = (value: string | undefined | null): Item[] => {
    if (value == null || value.trim() === '' || !value.includes('\n')) {
      return daysOfWeek.map(day => ({
        day,
        open: '',
        close: '',
      }));
    }
    const items = value.split('\n').map(item => {
      const [day, time] = item.split(' ');
      const [open, close] = time.split('-');
      return {
        day,
        open,
        close,
      };
    });
    return items;
  };

  const stringify = (value: Item[]): string =>
    value.map(item => `${item.day} ${item.open}-${item.close}`).join('\n');

  transform(data => ({
    collection_site: {
      ...data,
      password: data.password,
      password_confirmation: data.password_confirmation,
    },
  }));

  transform(nestedAttributes(['requisition_form_set']));

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      collectionSite.id != null ? 'patch' : 'post',
      `/admin/collection_sites/${collectionSite.id ?? ''}`,
    );
  };

  useEffect(() => {
    if (data.password) {
      setData('password_confirmation', data.password);
    }
  }, [data.password]);

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Stack>
            <Field label={t('collection_site.column.name')} required>
              <TextInput
                required
                value={data.name || ''}
                onChange={e => {
                  setData('name', e.target.value);
                }}
                error={errors.name}
              />
            </Field>
            <AddressAutocomplete
              address={addressDetails}
              carmen={carmen}
              errors={errors}
              api_key={api_key}
              onAddressChange={handleAddressChange}
            />
            <Field label={t('collection_site.column.email')}>
              <TextInput
                value={data.email || ''}
                onChange={e => {
                  setData('email', e.target.value);
                }}
                error={errors.email}
              />
            </Field>
            <Field label={t('collection_site.column.phone')} required>
              <TextInput
                required
                value={data.phone || ''}
                onChange={e => {
                  setData('phone', e.target.value);
                }}
                error={errors.phone}
              />
            </Field>
            <Field label={t('collection_site.column.opening_hours')} required>
              <HoursOfOperation
                value={parse(data.opening_hours) || []}
                onChange={value => {
                  setData('opening_hours', stringify(value));
                }}
                errors={errors}
              />
            </Field>
            <Field label={t('collection_site.column.booking_site_url')}>
              <TextInput
                value={data.booking_site_url || ''}
                onChange={e => {
                  setData('booking_site_url', e.target.value);
                }}
                error={errors.booking_site_url}
                placeholder="http://"
              />
            </Field>
            <Field
              label={t('collection_site.column.booking_preference')}
              required
            >
              <Select
                name="booking_preference"
                value={data.booking_preference || ''}
                onChange={value => {
                  setData('booking_preference', value as string);
                }}
                data={COLLECTION_SITE_BOOKING_PREFERENCE}
                error={errors.booking_preference}
              />
            </Field>
            <Field label={t('collection_site.column.custom_instructions')}>
              <Textarea
                name="custom_instructions"
                value={data.custom_instructions || ''}
                onChange={e => {
                  setData('custom_instructions', e.target.value);
                }}
                error={errors.custom_instructions}
              />
            </Field>
            <Field label={t('collection_site.column.parent_lab_url')}>
              <TextInput
                value={data.parent_lab_url || ''}
                onChange={e => {
                  setData('parent_lab_url', e.target.value);
                }}
                error={errors.parent_lab_url}
                placeholder="http://"
              />
            </Field>
            <Field label={t('collection_site.column.parent_lab_username')}>
              <TextInput
                value={data.parent_lab_username || ''}
                onChange={e => {
                  setData('parent_lab_username', e.target.value);
                }}
                error={errors.parent_lab_username}
              />
            </Field>
            <Field label={t('collection_site.column.password')}>
              <TextInput
                value={data.password || ''}
                onChange={e => {
                  setData('password', e.target.value);
                }}
                error={errors.password}
              />
            </Field>
            <Field
              label={t('collection_site.column.associated_requisition_forms')}
            >
              <RequisitionFormSet
                value={data.requisition_form_set || []}
                onChange={value => {
                  setData('requisition_form_set', value);
                }}
                data={COLLECTION_SITE_REQUISITION_FORM_SET.map(val => ({
                  value: val,
                  label: t(`collection_site.requisition_form_set.${val}`),
                }))}
              />
            </Field>
          </Stack>
        </Card.Section>

        <Card.Section>
          <Group p="md" justify="end">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
