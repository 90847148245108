import { Link } from '@inertiajs/react';
import { Badge, Card } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Assay, Client, Site } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  client: Client & {
    shipping_address: Record<string, string>;
    site: Site;
  };
  assay: Assay[];
}

export function Details({ client }: Props) {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Section>
        <Field label={t('client.column.name')}>
          {client.first_name} {client.last_name}
        </Field>

        <Field label={t('client.column.email')}>
          <a href={`mailto:${client.email}`}>{client.email}</a>
        </Field>

        <Field label={t('client.column.phone')}>{client.phone}</Field>
        <Field label={t('client.column.date_of_birth')}>
          {client.date_of_birth}
        </Field>
        <Field label={t('client.column.sex')}>{client.sex}</Field>
        <Field label="Address">{client.shipping_address?.full_address}</Field>
        <Field label={t('user.column.status')}>
          <Badge
            color={client.invitation_accepted_at !== null ? 'green' : 'red'}
          >
            {client.invitation_accepted_at !== null
              ? t('client.invite_status.invitation_accepted')
              : t('client.invite_status.invitation_unaccepted')}
          </Badge>
        </Field>
        <Field label={t('common.column.locked_at')}>
          <Badge color={client.locked_at !== null ? 'red' : 'green'}>
            {client.locked_at !== null
              ? `${t('common.state.locked_at')}  ${formatDate(client.locked_at)}`
              : t('common.state.unlocked')}
          </Badge>
          {client.locked_at && (
            <Link
              style={{ paddingLeft: 15 }}
              href={`/admin/clients/${client.id}/unlock`}
              method="post"
            >
              {t(`common.action.send_unlock_instructions`)}
            </Link>
          )}
        </Field>

        <Field label={t('client.column.share_with_provider?')}>
          {t(`common.boolean.${client.share_with_provider ? 'true' : 'false'}`)}
        </Field>

        <Field label={t('client.column.beta_features_enabled?')}>
          {t(
            `common.boolean.${client.beta_features_enabled ? 'true' : 'false'}`,
          )}
        </Field>

        <Field label={t('site.singular')}>{client.site.description}</Field>
      </Card.Section>
    </Card>
  );
}
