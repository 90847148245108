import { Page } from '@/components';
import type { Address, Carmen, Client, Site } from '@/types';

import { Form } from './_Form';

interface Props {
  client: Client;
  sites: Site[];
  carmen: Carmen;
  address: Address;
  api_key: string;
}

export default function New(props: Props) {
  return (
    <Page title="Update Client">
      <Form
        client={props.client}
        sites={props.sites}
        carmen={props.carmen}
        address={props.address}
        api_key={props.api_key}
      />
    </Page>
  );
}
