import { Page } from '@/components';
import type { Address, Carmen, Provider, Site } from '@/types';

import { Form } from './_Form';

interface Props {
  sites: Site[];
  provider?: Provider;
  carmen: Carmen;
  api_key: string;
  address: Address;
}

export default function New({
  provider,
  sites,
  carmen,
  address,
  api_key,
}: Props) {
  return (
    <Page title="Create Staff">
      <Form
        sites={sites}
        provider={provider}
        carmen={carmen}
        api_key={api_key}
        address={address}
      />
    </Page>
  );
}
