import { Button, Card, NativeSelect, Stack, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { type SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SEX } from '@/constants';
import { useForm } from '@/hooks';
import type { Address, Carmen, Client } from '@/types';
import { nestedAttributes } from '@/utils';

import { ClientAddressAutocomplete } from './_ClientAddressAutocomplete';

interface Props {
  action: string;
  client: Client;
  fromSummary?: boolean;
  apiKey: string;
  carmen: Carmen;
  address: Address;
}

export function PersonalInfoForm({
  action,
  client,
  fromSummary,
  apiKey,
  carmen,
  address,
}: Props) {
  const { t } = useTranslation();

  const [localErrors, setLocalErrors] = useState<Record<string, string>>({});
  const { getPath, setPath, patch, errors, setData, transform } =
    useForm(client);

  transform(nestedAttributes(['addresses']));

  const handleAddressChange = (updatedAddress: Address) => {
    setData('addresses', [updatedAddress]);
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors: Record<string, string> = {};
    const address = getPath('addresses')[0];

    if (!address.country || address.country.trim() === '') {
      newErrors['addresses[0].country'] = 'Country is required';
      isValid = false;
    }
    if (!address.province_state || address.province_state.trim() === '') {
      newErrors['addresses[0].province_state'] = 'Province/State is required';
      isValid = false;
    }
    setLocalErrors(newErrors);
    return isValid;
  };

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    if (validateFields()) {
      patch(action);
    }
  };

  return (
    <form onSubmit={save}>
      <Stack gap="md">
        <Card padding="xl">
          <Card.Section withBorder p="xl">
            <Stack gap="sm">
              <TextInput
                required
                label={t('client.column.first_name')}
                name="first_name"
                value={getPath('first_name', '')}
                onChange={e => setPath('first_name', e.target.value)}
                error={errors.first_name}
              />
              <TextInput
                required
                label={t('client.column.last_name')}
                name="last_name"
                value={getPath('last_name', '')}
                onChange={e => setPath('last_name', e.target.value)}
                error={errors.last_name}
              />
              <TextInput
                name="preferred_name"
                label={t('client.column.preferred_name')}
                value={getPath('preferred_name', '')}
                onChange={e => setPath('preferred_name', e.target.value)}
                error={errors.preferred_name}
              />
              <DateInput
                required
                label={t('client.column.date_of_birth')}
                name="date_of_birth"
                placeholder="YYYY-MM-DD"
                value={
                  getPath('date_of_birth')
                    ? dayjs(getPath('date_of_birth'), 'YYYY-MM-DD').toDate()
                    : null
                }
                defaultLevel="decade"
                onChange={value =>
                  setPath('date_of_birth', dayjs(value).format('YYYY-MM-DD'))
                }
                error={errors.date_of_birth}
              />
              <NativeSelect
                required
                label={t('client.column.sex')}
                name="sex"
                value={getPath('sex', '')}
                onChange={e => setPath('sex', e.target.value)}
                data={[
                  '',
                  ...SEX.map(sex => ({
                    value: sex,
                    label: t(`client.sex.${sex}`),
                  })),
                ]}
                error={errors.sex}
              />
              <TextInput
                label={t('client.column.phone')}
                required
                name="phone"
                value={getPath('phone', '')}
                onChange={e => setPath('phone', e.target.value)}
                error={errors.phone}
              />
            </Stack>
          </Card.Section>
          <Card.Section withBorder p="xl">
            <ClientAddressAutocomplete
              address={address}
              onAddressChange={handleAddressChange}
              carmen={carmen}
              api_key={apiKey}
              errors={{ ...errors, ...localErrors }}
            />
          </Card.Section>
          <Button type="submit">
            {t(`common.action.${fromSummary != null ? 'submit' : 'continue'}`)}
          </Button>
        </Card>
      </Stack>
    </form>
  );
}
